<template>
    <div class="full-screen-page">
        <div class="container-fluid">
            <div class="page-content">
                <slot></slot>
                <div class="locales-wrap">
                    <button
                        class="locale-btn text-h4"
                        :class="{ active: $i18n.locale === 'ua' }"
                        @click="setLocale('ua')"
                    >
                        UA
                    </button>
                    <button
                        class="locale-btn text-h4"
                        :class="{ active: $i18n.locale === 'en' }"
                        @click="setLocale('en')"
                    >
                        EN
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FullPageLayout',
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale
            localStorage.setItem('locale', locale)
        },
    },
}
</script>

<style lang="scss">
@import '@/scss/common/_colors.scss';

.full-screen-page {
    display: flex;
    align-items: center;
    height: 100%;
    background: url('@/assets/auth-bg.jpg') no-repeat center right;
    background-size: cover;

    .page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: -8rem auto 0;
        max-width: 45rem;
    }

    .form-title {
        font-size: 2.8rem;
        margin-bottom: 2rem;
    }

    .form-description {
        margin-bottom: 5rem;
    }

    .sign-form {
        width: 100%;
    }

    .form-btn {
        margin-top: 3rem;
    }

    .secondary-link {
        text-decoration: none;
        margin-top: 2rem;

        &:hover {
            text-decoration: underline;
        }
    }

    .locales-wrap {
        margin-top: 2rem;

        .locale-btn {
            padding: 0.5rem 0.8rem;
            border-radius: 0.8rem;
            background: transparent;
            border: none;
            cursor: pointer;
            margin-right: 0.8rem;

            &.active {
                background: #ffaf00;
                color: $white;
            }
        }
    }
}

@media screen and (max-width: 991px), (max-height: 700px) {
    .full-screen-page {
        .page-content {
            margin: 0 auto;
        }
    }
}
</style>
